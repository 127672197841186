<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-home-group</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Properties ({{ totalProperties }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <div class="property-details__status_action">
        <v-btn
          v-if="hasCreatePermission"
          color="primary"
          class="appbar__btn"
          height="40px"
          :to="{ name: 'property.create' }"
        >
          <v-icon class="mr-2">{{ icons.add }}</v-icon>
          Create Property
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :style="{ backgroundColor: '#606060' }"
              :loading="loadingAction"
              height="40px"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">{{ icons.chevronDown }}</v-icon>
              Bulk Updates
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title
                @click="actionHandel(item.value)"
                :style="{ cursor: 'pointer' }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="appbar__btn font-weight-bold"
          height="40px"
          @click="exportList"
        >
          <v-icon class="mr-2">mdi-export-variant</v-icon>
          EXPORT
        </v-btn>
      </div>
    </v-app-bar>

    <div
      class="mx-4"
      id="property-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.index"
            :ref="item.index"
            :disabled="defaultStatus !== item.index && loading"
            @click="handleTabClick(item.index)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title or building"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="properties"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        show-select
        :server-items-length="totalProperties"
        @click:row="editItem"
        @update:options="fetch"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.building="{ item }">
          <div v-if="item.buildingName">{{ item.buildingName }}</div>
        </template>
        <template v-slot:item.status="{ item }">
          <v-checkbox
            v-model="item.isActive"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.created_by="{ item }">
          <div v-if="item.createdBy">{{ item.createdBy }}</div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Property list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPlus, mdiChevronDown } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'PropertiesPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      loadingAction: false,
      filter: {
        search: '',
      },
      icons: {
        chevronDown: mdiChevronDown,
        add: mdiPlus,
      },
      headers: [
        {
          text: 'Property Name',
          value: 'name',
        },
        {
          text: 'Building',
          value: 'building',
          sortable: false,
        },
        {
          text: 'Address',
          value: 'address',
        },
        {
          text: 'Active',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
        {
          text: 'Added by',
          value: 'created_by',
          sortable: false,
        },
      ],
      selected: [],
      options: {
        sortBy: ['name'],
        sortDesc: [true],
      },
      defaultStatus: 0,
      tabItems: [
        { tab: 'Active', index: 0 },
        { tab: 'Inactive', index: 1 },
        { tab: 'All', index: 4 },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
        {
          value: 'active',
          label: 'Set as Active',
        },
        {
          value: 'inactive',
          label: 'Set as inactive',
        },
      ],
    }
  },

  created() {
    this.fetchInitParams()
    this.clearProperties()
    this.fetch(this.options)
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      properties: (state) => state.property.list,
      listMeta: (state) => state.property.listMeta,
      permissions: (state) => state.auth.permissions,
      totalProperties: (state) => state.property.totalProperties,
      params: (state) => state.property.params,
      scrollPosition: (state) => state.property.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.PROPERTY_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getProperties: 'property/getProperties',
      bulkActionProperty: 'property/bulkActionProperty',
      exportToExcel: 'property/exportToExcel',
    }),

    ...mapMutations({
      clearProperties: 'property/clearPropertyList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'property/setParams',
      setScrollPosition: 'property/setScrollPosition',
    }),

    filterList(filterValue) {
      this.clearProperties()
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.clearProperties()
      this.fetch(this.options)
    },

    search: debounce(function () {
      this.clearProperties()
      this.fetch(this.options)
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        options: {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalProperties
              : options.itemsPerPage
            : 25,
          sort: options?.sortBy[0]
            ? options?.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
        },
        status: this.defaultStatus,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.setParams(params)

      this.loading = true
      await this.getProperties(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true

      let params = {
        options: {
          page: this.options?.page || 1,
          itemsPerPage: this.options?.itemsPerPage
            ? this.options?.itemsPerPage === -1
              ? this.totalProperties
              : this.options.itemsPerPage
            : 25,
          sort: this.options?.sortBy[0]
            ? this.options?.sortDesc[0]
              ? '-' + this.options.sortBy[0]
              : this.options.sortBy[0]
            : '',
        },
        status: this.defaultStatus,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      await this.exportToExcel(params)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'properties_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('Property successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'property.details',
        params: { id: item.id },
      })
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.selected = []
      this.activeAction()
    },

    fetchInitParams() {
      this.defaultStatus = this.params.status

      this.options = this.params.options

      this.filter.search = this.params.search
    },

    actionHandel(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select properties', false)
        return
      }

      this.bulkActions(action)
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('property_ids[]', property.id)
      })

      await this.bulkActionProperty(formData)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearProperties()
          this.fetch()
        })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.property-details__status_action {
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  gap: 20px;
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
